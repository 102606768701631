// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academics-js": () => import("./../../../src/pages/academics.js" /* webpackChunkName: "component---src-pages-academics-js" */),
  "component---src-pages-admissions-js": () => import("./../../../src/pages/admissions.js" /* webpackChunkName: "component---src-pages-admissions-js" */),
  "component---src-pages-alumni-js": () => import("./../../../src/pages/alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-bus-routes-js": () => import("./../../../src/pages/bus-routes.js" /* webpackChunkName: "component---src-pages-bus-routes-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cbse-corner-js": () => import("./../../../src/pages/cbse-corner.js" /* webpackChunkName: "component---src-pages-cbse-corner-js" */),
  "component---src-pages-cbse-results-js": () => import("./../../../src/pages/cbse-results.js" /* webpackChunkName: "component---src-pages-cbse-results-js" */),
  "component---src-pages-chinmaya-mission-trust-js": () => import("./../../../src/pages/chinmaya-mission-trust.js" /* webpackChunkName: "component---src-pages-chinmaya-mission-trust-js" */),
  "component---src-pages-chinmaya-vision-programme-js": () => import("./../../../src/pages/chinmaya-vision-programme.js" /* webpackChunkName: "component---src-pages-chinmaya-vision-programme-js" */),
  "component---src-pages-circulars-js": () => import("./../../../src/pages/circulars.js" /* webpackChunkName: "component---src-pages-circulars-js" */),
  "component---src-pages-contact-information-js": () => import("./../../../src/pages/contact-information.js" /* webpackChunkName: "component---src-pages-contact-information-js" */),
  "component---src-pages-e-magazine-2021-js": () => import("./../../../src/pages/e-magazine-2021.js" /* webpackChunkName: "component---src-pages-e-magazine-2021-js" */),
  "component---src-pages-exam-schedule-js": () => import("./../../../src/pages/exam-schedule.js" /* webpackChunkName: "component---src-pages-exam-schedule-js" */),
  "component---src-pages-fee-structure-js": () => import("./../../../src/pages/fee-structure.js" /* webpackChunkName: "component---src-pages-fee-structure-js" */),
  "component---src-pages-gurudevs-journey-js": () => import("./../../../src/pages/gurudevs-journey.js" /* webpackChunkName: "component---src-pages-gurudevs-journey-js" */),
  "component---src-pages-hall-of-fame-js": () => import("./../../../src/pages/hall-of-fame.js" /* webpackChunkName: "component---src-pages-hall-of-fame-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infrastructure-js": () => import("./../../../src/pages/infrastructure.js" /* webpackChunkName: "component---src-pages-infrastructure-js" */),
  "component---src-pages-laboratories-js": () => import("./../../../src/pages/laboratories.js" /* webpackChunkName: "component---src-pages-laboratories-js" */),
  "component---src-pages-managing-committee-js": () => import("./../../../src/pages/managing-committee.js" /* webpackChunkName: "component---src-pages-managing-committee-js" */),
  "component---src-pages-nursery-admissions-js": () => import("./../../../src/pages/nursery-admissions.js" /* webpackChunkName: "component---src-pages-nursery-admissions-js" */),
  "component---src-pages-online-registration-process-js": () => import("./../../../src/pages/online-registration-process.js" /* webpackChunkName: "component---src-pages-online-registration-process-js" */),
  "component---src-pages-performance-arts-js": () => import("./../../../src/pages/performance-arts.js" /* webpackChunkName: "component---src-pages-performance-arts-js" */),
  "component---src-pages-principal-message-js": () => import("./../../../src/pages/principal-message.js" /* webpackChunkName: "component---src-pages-principal-message-js" */),
  "component---src-pages-pta-executive-committee-js": () => import("./../../../src/pages/pta-executive-committee.js" /* webpackChunkName: "component---src-pages-pta-executive-committee-js" */),
  "component---src-pages-registrations-js": () => import("./../../../src/pages/registrations.js" /* webpackChunkName: "component---src-pages-registrations-js" */),
  "component---src-pages-rules-and-regulations-js": () => import("./../../../src/pages/rules-and-regulations.js" /* webpackChunkName: "component---src-pages-rules-and-regulations-js" */),
  "component---src-pages-school-calendar-js": () => import("./../../../src/pages/school-calendar.js" /* webpackChunkName: "component---src-pages-school-calendar-js" */),
  "component---src-pages-school-gallery-js": () => import("./../../../src/pages/school-gallery.js" /* webpackChunkName: "component---src-pages-school-gallery-js" */),
  "component---src-pages-school-uniform-js": () => import("./../../../src/pages/school-uniform.js" /* webpackChunkName: "component---src-pages-school-uniform-js" */),
  "component---src-pages-sports-js": () => import("./../../../src/pages/sports.js" /* webpackChunkName: "component---src-pages-sports-js" */),
  "component---src-pages-subjects-js": () => import("./../../../src/pages/subjects.js" /* webpackChunkName: "component---src-pages-subjects-js" */),
  "component---src-pages-syllabus-js": () => import("./../../../src/pages/syllabus.js" /* webpackChunkName: "component---src-pages-syllabus-js" */),
  "component---src-pages-transfer-certificate-js": () => import("./../../../src/pages/transfer-certificate.js" /* webpackChunkName: "component---src-pages-transfer-certificate-js" */),
  "component---src-pages-transportation-js": () => import("./../../../src/pages/transportation.js" /* webpackChunkName: "component---src-pages-transportation-js" */)
}

